.variant-glass {
  --spatial-shadow-h: 4px;
  --frame3d: 0 var(--spatial-shadow-h) 0 0px rgba(255, 255, 255, 0.375);
  --frameInset: inset 0 0 theme('spacing.2') 0.2rem rgba(255, 255, 255, 0.375);
  --tw-shadow: var(--frameInset, 0 0 #0000), var(--frame3d, 0 0 #0000), var(--normal-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:global(.dark) .variant-glass {
  --frame3d: 0 var(--spatial-shadow-h) 0 0px rgba(0, 0, 0, 0.375);
  --frameInset: inset 0 0 theme('spacing.2') 0.2rem rgba(0, 0, 0, 0.2);
}
